import React  from 'react';
import ClientHeader from "../layout/ClientHeader"
import TabSelector from "../layout/TabSelector"
import { ViewBill } from "./ViewBill"
import { CreateBill } from "./CreateBill"
import { useState } from "react"
import { GetRequiredView } from "../Navigator"
import { useTranslation } from 'react-i18next';
import { GetAllHeader, GetNoSelectionHeader } from '../view/HeadersUtils';


const BillManager = () => {
  const { t, i18n } = useTranslation();

  const [itemToEdit, setItemToEdit] = useState(null)
  const [itemNoToView, setItemNoToView] = useState(null)
  const [currentView, setCurrentView] = useState(GetRequiredView())
  const [availViews, setAvailViews] = useState(GetNoSelectionHeader(t))
  
    const handleEdit = (item) => {
      setItemToEdit(item)
      setAvailViews(GetAllHeader(t))
      setCurrentView("edit")
    }

    const saveCallback = (item) => {
      setItemToEdit(null)
      setItemNoToView(item.no)
      setAvailViews(GetNoSelectionHeader(t))
      setCurrentView("view")
    }
    return (
        <>
        <ClientHeader 
          allowSelection={currentView !== "edit"} 
          showAnimals={currentView !== "view"}
          allowAnimalClearable={true}/>
        <TabSelector 
          onViewChange={setCurrentView}
          pageTitle={t('billing.title')}
          availableView={availViews}
          currentView={currentView}
          />
          { currentView === "view" && <ViewBill itemNo={itemNoToView} handleEdit={handleEdit} /> }
          { currentView === "edit" && <CreateBill itemToEdit={itemToEdit} saveCallback={saveCallback}/> }
          { currentView === "create" && <CreateBill saveCallback={saveCallback} /> }
        </>
      );
}

export default BillManager