import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from "react-router-dom";
import { useLocation} from 'react-router-dom';
import logo from '../img/logo-nb.png'
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AnimalIconContent, ClientIconContent, ConsultationIconContent, EventConfirmIconContent , EventRequestIconContent, FactureIconContent, HeaderIconContent, PrescriptionIconContent, VaccinIconContent, Vue360IconContent} from './common/IconFactory';
import { useTranslation } from 'react-i18next';
import { hasModuleBilling, hasModuleConsultation, hasModuleEventConfirm, hasModuleInccomingEvent, hasModuleRx, hasModuleVaccine } from '../services/AccessHub';

//Event navigation
export const eventsRequestUrl = "/eventsRequest";
export const eventsConfirmUrl = "/eventsConfirm";
//Client navigation
export const clientUrl = "/clients";
export const animalUrl = "/animals";
export const billUrl = "/factures";
export const view360Url = "/vue360";
//Medic navigation
export const consultationUrl = "/consultations";
export const rxUrl = "/prescriptions";
export const vaccineUrl = "/vaccins";

//Other navigation
export const accountUrl = "/account";
export const overviewUrl = "/overview";


export const GetRequiredView = () => {
  const location = useLocation();
  const askView = location.state?.view

  return askView ? askView : "view"
}




const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const [t, i18n] = useTranslation()
  const { ...other } = props;

  const location = useLocation();
  let navigate = useNavigate(); 

  let categories = [
    {
      id: t('navigation.eventSection'),
      icon: <CalendarMonthIcon/>,
      access: hasModuleInccomingEvent() || hasModuleEventConfirm(),
      children: [
        { id: t('navigation.eventPage'), access: hasModuleInccomingEvent() , nav: eventsRequestUrl, icon: <EventRequestIconContent/> , active: location.pathname === eventsRequestUrl},
        { id: t('navigation.eventConfirmPage'), access: hasModuleEventConfirm() ,nav: eventsConfirmUrl, icon:  <EventConfirmIconContent/> , active: location.pathname === eventsConfirmUrl},
      ],
    },
    {
      id: t('navigation.clientSection'),
      icon: <ContactPageIcon />,
      access: true,
      children: [
        { id: t('client.title'), access: true, nav: clientUrl, icon: <ClientIconContent/>, active: location.pathname === clientUrl},
        { id: t('animal.title'), access: true, nav: animalUrl, icon: <AnimalIconContent/>, active: location.pathname === animalUrl },
        { id: t('billing.title'), access: hasModuleBilling(), nav: billUrl, icon: <FactureIconContent/>, active: location.pathname === billUrl },
        { id: 'Vue 360', access: true, nav: view360Url, icon: <Vue360IconContent/>, active: location.pathname === view360Url },
      ],
    },
    {
      id: t('navigation.medicSection'),
      icon: <MedicalInformationIcon />,
      access: hasModuleConsultation() || hasModuleRx() || hasModuleVaccine(),
      children: [
        { id: t('consultation.title'), access: hasModuleConsultation(), nav: consultationUrl, icon: <ConsultationIconContent/>, active: location.pathname === consultationUrl},
        { id: t('rx.title'), access: hasModuleRx(), nav: rxUrl, icon: <PrescriptionIconContent/>, active: location.pathname === rxUrl},
        { id: t('vaccine.title'), access: hasModuleVaccine(), nav: vaccineUrl, icon: <VaccinIconContent/> , active: location.pathname === vaccineUrl}
      ],
    }
  ];



  function itemClick(destination) {
    console.log("Nav to : " + destination)
    navigate(destination)
    if (props.onClose) {
      props.onClose()
    }
  }


  return (
    <Drawer variant="permanent" {...other}>
      <List key="main-list" disablePadding>
        <ListItem key="icon-logo" sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          <img src={logo} className="App-MainLogo" alt="logo"/>
        </ListItem>
        { 1 === 1 &&
          <ListItem key="overview-list" sx={{ ...item, ...itemCategory }} >
            <ListItemButton selected={location.pathname === overviewUrl} sx={item} >
              <ListItemIcon onClick={function(e) {itemClick(overviewUrl)}}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText onClick={function(e) {itemClick(overviewUrl)}}>Vue d'ensemble</ListItemText>
            </ListItemButton>
          </ListItem>
        }
        {categories.map(({ id, access, children, icon }) => (

          <>
          { (access) &&
            <Box key={id} sx={{ bgcolor: '#101F33' }}>
              <ListItem key={id} sx={{ py: 2, px: 3 }}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
              </ListItem>
              {children.map(({ id: childId, access, icon, active, nav }) => (
                <>
                  {
                    (access) &&
                
                  <ListItem disablePadding key={childId}>
                      <ListItemButton selected={active} sx={item}>
                        <ListItemIcon onClick={function(e) {itemClick(nav)}} >{icon}</ListItemIcon>
                        <ListItemText onClick={function(e) {itemClick(nav)}}>{childId}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  }
                </>
              ))}
              <Divider sx={{ mt: 2 }} />
            </Box>
          }
          </>
        ))}
      </List>
    </Drawer>
  );
}
