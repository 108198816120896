import React, { useEffect, useState } from 'react'
import EmptyView from '../view/EmptyView';
import { Paper, Typography } from '@mui/material';
import {CreateButtonSection} from '../view/CreateButtonSection';
import { useTranslation } from 'react-i18next';
import Headers from '../../api/Headers';
import { DisplayPreferenceTable } from './HeaderTable';
import { useDispatch } from 'react-redux';
import {updateHeader} from './BaseHeader'
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';

export function ViewPreferenceHeader({navigateAction, handleEdit}) {
  const [t, i18n] = useTranslation()
  const [isEditMode, setIsEditMode] = useState(true);
  const dispatch = useDispatch()
  const [curHeader, setCurHeader] = useState(null);

  useEffect(()=>{
    if (!curHeader){
      dispatch(addLoadingCount())
      new Headers()
        .Get("default.json")
        .then(setCurHeader)
        .finally(()=>dispatch(subLoadingCount()))
    }
},[]);

  if (curHeader === undefined || curHeader === null) {
    return (
      <EmptyView title={t('header.noSelection')}>
        
      </EmptyView>
    )
  }
  const handleInputChange = (fieldName, value, section) => {
    if (section) {
      setCurHeader( {...curHeader,
        preference: 
          {...curHeader.preference,
            [section]:  {
              ...curHeader.preference[section],
              [fieldName]: value
            }
          }
      })
    } else {
      setCurHeader( {...curHeader,
        preference: 
          {...curHeader.preference,
            [fieldName]: value
          }
      })
    }
  };

  const getView =(curHeader, isEditMode) => {
    return (
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
        <div border="1">
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left" variant='h5'>
          {t('header.config.title')}
            </Typography>
            <form id="prefForm" onSubmit={(e) => updateHeader(e, curHeader, null, dispatch, t)}>
              <DisplayPreferenceTable 
                items={curHeader.preference} 
                handleConfigInputChange={handleInputChange} 
                isEditMode={isEditMode} /> 
              <CreateButtonSection />
            </form>
          </div>
        </Paper>
      )
  }

    return getView(curHeader, isEditMode)
}