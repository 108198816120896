import React  from 'react';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import {  fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import userpool from '../component/userpool';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import {userStore} from '../component/common/Session'
import env from '../env/env.json';
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity"
import avCindy from '../img/avatar/cindy.jpg'
import { Avatar } from '@mui/material';

function IsAdmin() {
    if (userpool.getCurrentUser() === null) {
        console.log("You are currently not loggin")
        return null
    }
    validateRefresh()
     
    return userStore.getIdToken()?.payload["custom:isAdmin"]
}

function GetUserRoles() {
    if (userpool.getCurrentUser() === null) {
        console.log("You are currently not loggin")
        return []
    }
    validateRefresh()
     
    return userStore.getIdToken()?.payload['cognito:groups']
}

function HasRole(role) {
    const roles = GetUserRoles()
    return roles?.findIndex((curRole) => curRole === role) > -1
}


function GetUserIcon(username) {
    if (!username) {
        return <NoAccountsIcon/>
    }
    const un = username
    if (un === "cindy.lefebvre@gmail.com") {
        
        return <Avatar src={avCindy}  />
    } else if (un === "compagnat") {
        return <AdminPanelSettingsIcon/>
    }

    return <PersonOutlineIcon />
}


const Authenticate=(Email,Password)=>{
    return new Promise((resolve,reject,newPassword)=>{
        const user=new CognitoUser({
            Username:Email,
            Pool:userpool
        });

        const authDetails= new AuthenticationDetails({
            Username:Email,
            Password
        });
        user.authenticateUser(authDetails,{
            onSuccess:(result)=>{
                console.log("login successful");
                resolve()               
            },
            onFailure:(err)=>{
                console.log("login failed",err);
                reject(err);
            }, 
            newPasswordRequired: userAttr => {
                console.log("Need to create new password");
                reject({isFirstLoging: true, user: user, userAttr: userAttr})
                
            }
        });
    });
};

const changePassword = (newPassword, user, userAttr) => {
    console.log("Call change password")
    user.changePassword(newPassword, userAttr, {
        onSuccess:(result)=>{
            console.log("New password successful");
        },
        onFailure:(err)=>{
            console.log("New password failed",err);
        }
    });
  };



const GetAwsCreds = async (region) => {
    const idToken = GetAccessToken()


    const cognitoidentity = new CognitoIdentityClient({
        credentials : fromCognitoIdentityPool({
            client : new CognitoIdentityClient({"region": env.aws_cognito_region}),
            identityPoolId : env.aws_cognito_identity_pool_id,
            "region": env.aws_cognito_region,
            logins: {
              ["cognito-idp.ca-central-1.amazonaws.com/" + env.REACT_APP_USER_POOL_ID] : idToken
            }
         }),
    });
    const credentials = await cognitoidentity.config.credentials()

    credentials.region = region


    return (
        {
            "credentials": {
                "accessKeyId": credentials.accessKeyId,
                "secretAccessKey": credentials.secretAccessKey,
                "sessionToken": credentials.sessionToken,
                "expiration": credentials.expiration
            },
            "region": region
    })
}

function GetAccessToken() {
    if (userpool.getCurrentUser() === null) {
        console.log("You are currently not loggin")
        return null
    }
    validateRefresh()
     
  
    return userStore.getIdToken()?.jwtToken
}

function GetClientKey() {
    if (userpool.getCurrentUser() === null) {
        console.log("You are currently not loggin")
        return null
    }
    validateRefresh()
     
    return userStore.getIdToken()?.payload["custom:clientKey"]
}


function validateRefresh() {
    const token = userStore.getIdToken()  
    if (token !== null && token !== undefined) {
      //Validate current token
      if (new Date().getTime()/1000 > token.payload.exp) {
        userStore.setSession(null)
        fetchNewAccessToken()
      }
    } else {
        fetchNewAccessToken()
    }
}

  


function fetchNewAccessToken() {
    console.log("Fetch new token")
    userpool.getCurrentUser()?.getSession((error, session) => {
        if (error !== null) {
          userStore.setSession(null)
          console.error("fetchNewAccessToken : ", error)
          //alert(error);
        } else {
          console.log("New token define!")
          userStore.setSession(session)
        }
      });
    return userStore.getIdToken()
}
  

export {GetClientKey, GetAwsCreds, Authenticate, changePassword, IsAdmin, GetUserIcon, GetAccessToken, GetUserRoles, HasRole};
