import { createSlice } from '@reduxjs/toolkit'

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    header: null,
    logo: null,
    signature: null
  },
  reducers: {
    setHeader: (state, headerData) => {
      console.log("store : setHeader", headerData)
      state.header = headerData
    },
    setLogo: (state, logo) => {
      state.logo = logo;
    },
    setSignature: (state, sign) => {
      state.signature = sign;
    }
  }
})

export const GetWeightConf = (conf) => {
  return conf?.weight
}
export const GetWeightType = (conf, type) => {
  return GetWeightConf(conf) ? GetWeightConf(conf)[type] : null
}
export const GetPaiementEmail = (conf) => {
  return conf?.paiement_email
}
export const GetLicense = (conf) => {
  return conf?.license
}
// Action creators are generated for each case reducer function
export const { setHeader, setLogo, setSignature } = commonSlice.actions

export default commonSlice.reducer