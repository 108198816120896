//https://medium.com/@skysharinghisthoughts/simplifying-multilingual-support-in-react-with-i18next-a-step-by-step-guide-34621de40cbd
import React from 'react';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
//import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import env from './env/env.json';
import { LoadHeader } from './component/headers/HeaderLoader';
import { useSelector } from 'react-redux';
i18n
  .use(Backend) // Enables loading translation files over HTTP.
  .use(LanguageDetector) // Detects user language.
  .use(initReactI18next) // Initializes the react-i18next plugin.
  .init({
    supportedLngs: [ 'fr', 'en'], // Languages we're supporting.
    fallbackLng: "fr", // Fallback language if user's language isn't supported.
    debug: env.debug, // Logs info to the console.
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'], // Order of language detection.
      caches: ['cookie'], // Cache the detected language in cookies.
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json', // Path to the translation files.
    },
  });

export const SetupI18n = () => {
  const { t, i18n } = useTranslation()
  const header = useSelector(state => state.common.header)
  
  const curLang = header?.payload?.preference?.language
  if (curLang && i18n.language !== curLang) {
    console.log("Changing language to : ", curLang)
    i18n.changeLanguage(curLang)
  }
  
  return (<LoadHeader />)
};

export default i18n;