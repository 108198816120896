
import React  from 'react';
import { useState } from "react"
import TabSelector from "../layout/TabSelector"
import { ViewCompagnieHeader } from './ViewCompagnieHeader';
import { useTranslation } from 'react-i18next';
import { ViewPreferenceHeader } from './ViewPreferenceHeader';


const HeaderManager = () => {
    const [t, i18n] = useTranslation()
    const [currentView, setCurrentView] = useState("cie")
    const [availViews, setAvailViews] = useState([{key: 0, title: t('header.description'), name: "cie"},
    {key: 1, title: t('header.config.title'), name: "pref"}
    ])

    return (
        <>
            <TabSelector 
            onViewChange={setCurrentView}
            pageTitle={t('header.title')}
            availableView={availViews}
            currentView={currentView}
            />
            { currentView === "cie" && <ViewCompagnieHeader /> }
            { currentView === "pref" && <ViewPreferenceHeader />}
        </>
        );
}


export default HeaderManager

