import React from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, styled, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PhoneTextMask } from '../common/CommonTextMask';
import { LogoEditSection } from '../common/Logo';

const TextFieldStyle = styled(TextField)({ marginTop:16, marginBottom:16 });


function DisplayTable({items, handleInputChange, isEditMode}) {
    const { t, i18n } = useTranslation();  

    return (
        <MainHeaderSection item={items} handleInputChange={handleInputChange} isEditMode={isEditMode} t={t} />
    );
}



const MainHeaderSection = ({item, handleInputChange, isEditMode, t}) => {
    const variant = isEditMode ? 'outlined' : 'standard'
    return (
        <Box>
            <Typography sx={{ my: 2, mx: 4 }} color="text.secondary" align="left" >
                <LogoEditSection />
                <TextFieldStyle label={t('header.cieName')}
                    inputProps={{'aria-label': "txtCieName"}}
                    variant={variant}
                    fullWidth
                    disabled={!isEditMode} InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    value={item.name} />
                <TextFieldStyle label={t('header.phone')}
                    inputProps={{'aria-label': "txtPhone"}}
                    variant={variant}
                    fullWidth
                    disabled={!isEditMode} InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleInputChange('phone', e.target.value)}
                    value={item.phone}
                    inputComponent={PhoneTextMask}
                    />
                <TextFieldStyle label={t('header.addr1')}
                    inputProps={{'aria-label': "txtAddr1"}}
                    variant={variant}
                    fullWidth
                    disabled={!isEditMode} InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleInputChange('addr1', e.target.value)}
                    value={item.addr1} />
                <TextFieldStyle label={t('header.addr2')}
                    inputProps={{'aria-label': "txtAddr2"}}
                    variant={variant}
                    fullWidth
                    disabled={!isEditMode} InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleInputChange('addr2', e.target.value)}
                    value={item.addr2} />
                <TextFieldStyle label={t('header.email')}
                    inputProps={{'aria-label': "txtEmail"}}
                    variant={variant}
                    fullWidth
                    disabled={!isEditMode} InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleInputChange('email', e.target.value)}
                    value={item.email} />
            </Typography>
        </Box>
    )
}



function DisplayPreferenceTable({items, handleConfigInputChange, isEditMode}) {
    const { t, i18n } = useTranslation();  

    return (
        GetPreferenceRow(items, handleConfigInputChange, isEditMode, t)
    );
}

const GetPreferenceRow = (item, handleConfigInputChange, isEditMode, t) => {
    const variant = isEditMode ? 'outlined' : 'standard'
    return (
        <Box>
            <Typography sx={{ my: 2, mx: 4 }} color="text.secondary" align="left" >
                <Box spacing={2} align="left" >
                    <MainSection item={item} handleConfigInputChange={handleConfigInputChange} isEditMode={isEditMode} t={t} />
                    <WeightSection item={item} handleConfigInputChange={handleConfigInputChange} isEditMode={isEditMode} t={t} />
                </Box>
            </Typography>
        </Box>
    )

}


const MainSection = ({item, handleConfigInputChange, isEditMode, t}) => {
    const variant = isEditMode ? 'outlined' : 'standard'
    return (
        <Box>
            <FormControl>
                <FormLabel >{t('header.config.language')}</FormLabel>
                <RadioGroup 
                    row
                    onChange={(e) => handleConfigInputChange('language', e.target.value)}
                    value={item.language}>
                
                    <FormControlLabel disabled={!isEditMode} value={"fr"} control={<Radio />} label={t('header.config.fr')} />
                    <FormControlLabel disabled={!isEditMode} value={"en"} control={<Radio />} label={t('header.config.en')} />
                </RadioGroup>
            </FormControl>  
            <TextFieldStyle label={t('header.config.paiement_email')}
                variant={variant}
                fullWidth
                disabled={!isEditMode} InputLabelProps={{ shrink: true }}
                onChange={(e) => handleConfigInputChange('paiement_email', e.target.value)}
                value={item.paiement_email} />

            <TextFieldStyle label={t('header.config.vetName')}
                variant={variant}
                fullWidth
                disabled={!isEditMode} InputLabelProps={{ shrink: true }}
                onChange={(e) => handleConfigInputChange('vetName', e.target.value)}
                value={item.vetName} />
            <TextFieldStyle label={t('header.config.liscence')}
                variant={variant}
                fullWidth
                disabled={!isEditMode} InputLabelProps={{ shrink: true }}
                onChange={(e) => handleConfigInputChange('license', e.target.value)}
                value={item.license} />
        </Box>)
}



const WeightSection = ({item, handleConfigInputChange, isEditMode, t}) => {
    return (
        <Box>
            {t('header.config.weight')}
            <Typography sx={{ my: 2, mx: 4 }} color="text.secondary" align="left" >
            <GetWeightRadio 
                displayName={t('consultation.title')} 
                itemName="consultation" 
                item={item} 
                handleConfigInputChange={handleConfigInputChange} 
                isEditMode={isEditMode} 
                t={t} /> 
            </Typography>
            <Typography sx={{ my: 2, mx: 4 }} color="text.secondary" align="left" >
            <GetWeightRadio 
                displayName={t('rx.title')} 
                itemName="prescription" 
                item={item} 
                handleConfigInputChange={handleConfigInputChange} 
                isEditMode={isEditMode} 
                t={t} /> 
            </Typography>
            <Typography sx={{ my: 2, mx: 4 }} color="text.secondary" align="left" >
            <GetWeightRadio 
                displayName={t('vaccine.title')} 
                itemName="vaccin" 
                item={item} 
                handleConfigInputChange={handleConfigInputChange} 
                isEditMode={isEditMode} 
                t={t} /> 
            </Typography>
            <Typography sx={{ my: 2, mx: 4 }} color="text.secondary" align="left" >
            <GetWeightRadio 
                displayName={t('header.config.other')} 
                itemName="other" 
                item={item} 
                handleConfigInputChange={handleConfigInputChange} 
                isEditMode={isEditMode} 
                t={t} /> 
            </Typography>
         </Box>
    )
}


const TaxSection = ({item, handleConfigInputChange, isEditMode, t}) => {
    const variant = isEditMode ? 'outlined' : 'standard'
    const hasTax = item.hasTax
    return (
        <Box>
            <FormControl>
                <FormLabel >{t('header.config.hasTax')}</FormLabel>
                <RadioGroup 
                    row
                    onChange={(e) => handleConfigInputChange('hasTax', e.target.value)}
                    value={item.language}>
                
                    <FormControlLabel disabled={!isEditMode} value={true} control={<Radio />} label={t('yes')} />
                    <FormControlLabel disabled={!isEditMode} value={false} control={<Radio />} label={t('no')} />
                </RadioGroup>
            </FormControl>  
            <TextFieldStyle label={t('header.config.taxNumber')}
                variant={variant}
                fullWidth
                disabled={!isEditMode} InputLabelProps={{ shrink: true }}
                onChange={(e) => handleConfigInputChange('paiement_email', e.target.value)}
                value={item.paiement_email} />

        </Box>)
}






const GetWeightRadio = ({displayName, itemName, item, handleConfigInputChange, isEditMode, t}) => {
    return (
    <FormControl>
        <FormLabel >{displayName}</FormLabel>
        <RadioGroup 
            row
            onChange={(e) => handleConfigInputChange(itemName, e.target.value, "weight")}
            value={item.weight[itemName]}>
            <FormControlLabel disabled={!isEditMode} value={"kg"} control={<Radio />} label={t('header.config.kg')} />
            <FormControlLabel disabled={!isEditMode} value={"lb"} control={<Radio />} label={t('header.config.lb')} />
        </RadioGroup>
    </FormControl> )
}

/*

            "hasTax": "Inclure les taxes",
            "taxName": "Nom de la taxe",
            "taxRate": "Taux de la taxe",
            "taxNumber"

*/

/*
{
  "weight": {
    "consultation": "lb",
    "vaccin": "kg",
    "prescription": "kg",
    "other": "lb"
  },
  "language": "fr",
  "license": "4098",
  "tax": {
    "year_month_start": 1,
    "year_month_end": 12,
    "year_day_start": 1,
    "year_day_end": 31
  },
  "paiement_email": "paiement@cindyvet.com"
}


JSON.stringify(curHeader.preference, null, 2) 
*/

const DisplayPlanTable = (plan) => {

}



export {DisplayTable, DisplayPreferenceTable, DisplayPlanTable, TaxSection}