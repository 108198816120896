import Headers from "../../api/Headers";
import { setHeader } from "../common/CommonSlice";
import { messageStore } from "../common/InfoMessage";

const emptyHeader = { "name": "", "phone": "", "addr1": "", "addr2": "", "email": "" }




const updateHeader = (event, header, saveCallback, dispatch, t) => {
  event.preventDefault();
  return new Headers().Update(header).then((result) => {
    if (result.status === 200) {
      new Headers().Get("default.json").then(setHeader).then(dispatch).then(() => {
          if (saveCallback) {
            saveCallback(header)
          }
        })
        messageStore.sendMessage(t('saveSuccess'))
    } else {
      messageStore.sendMessage(result.statusText, "error", t('saveError') )
    }
  })
}



  export {emptyHeader, updateHeader}