import Typography from '@mui/material/Typography';
import * as React from 'react'
import TextField from '@mui/material/TextField';
import { PhoneTextMask } from '../common/CommonTextMask';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Link from '@mui/material/Link';

const emptyClient = {"isActif": true,  
  "no": "99999999999", 
  "name": "", 
  "phone": "", 
  "addr1": "",
  "addr2": "",
  "email": ""}


function GetClientSection({clientData, isEditMode, handleInputChange}) {

    return (<>
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left" >
            <TextField label="Numéro du client" sx={{ my: 2, mx: 2 }}
              inputProps={{'aria-label': "txtNo"}}
              required
              disabled
              value={clientData.no} variant="standard"/>
        
            <TextField label="Nom du client" sx={{ my: 2, mx: 2 }}
              
              required
              disabled={!isEditMode}
              fullWidth
              inputProps={{ maxLength: 60, 'aria-label': "txtName" }}
              onChange={(e) => handleInputChange('name', e.target.value)}
              value={clientData.name} variant="standard"/>
          
            <InputLabel 
              required disabled={!isEditMode} sx={{ my: 2, mx: 2 }} 
              htmlFor="tel">Numéro de téléphone</InputLabel>
            
            {!isEditMode && 
            <Link aria-label="lnkPhone" sx={{ my: 2, mx: 2 }} id="tel" href={"tel:" + clientData.phone}>{clientData.phone}</Link>}

            {isEditMode && 
              <Input sx={{ my: 2, mx: 2 }}
                inputProps={{'aria-label': "txtPhone"}}
                required
                value={clientData.phone}
                disabled={!isEditMode}
                name="textmask"
                onChange={(e) => handleInputChange('phone', e.target.value)}
                id="tel"
                inputComponent={PhoneTextMask}
              />
            }
              
            <TextField label="Ligne d'adresse 1" sx={{ my: 2, mx: 2 }}
              required
              disabled={!isEditMode}
              fullWidth
              inputProps={{ maxLength: 120, 'aria-label': "txtAddr1" }}
              onChange={(e) => handleInputChange('addr1', e.target.value)}
              value={clientData.addr1} variant="standard"/>

            <TextField label="Ligne d'adresse 2" sx={{ my: 2, mx: 2 }}
              disabled={!isEditMode}
              fullWidth
              inputProps={{ maxLength: 120, 'aria-label':"txtAddr2" }}
              onChange={(e) => handleInputChange('addr2', e.target.value)}
              value={clientData.addr2} variant="standard"/>
       
            {!isEditMode &&
            <>
              <InputLabel
                required 
                disabled={!isEditMode} sx={{ my: 2, mx: 2 }} 
                htmlFor="email">Courriel</InputLabel>

              <Link aria-label="lnkEmail" sx={{ my: 2, mx: 2 }} id="email" href={"mailto:" + clientData.email}>{clientData.email}</Link>
            </>}
            {isEditMode &&  <TextField id="email" label="Courriel" sx={{ my: 2, mx: 2 }}
                required
                disabled={!isEditMode}
                fullWidth
                inputProps={{ maxLength: 120, 'aria-label': "txtEmail" }}
                onChange={(e) => handleInputChange('email', e.target.value)}
                value={clientData.email} variant="standard"/>
            }
          </Typography>
            </>)

}
export {GetClientSection, emptyClient}
