import React  from 'react';
import DayPicker from './DatePicker';
import dayjs from 'dayjs';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';

function DayHelpPicker({referenceValue, value, inputChange, label, required, minDate, maxDate}) {
    const [helperValue, setHelperValue] = useState('');

    const helperChange = (event) => {
        setHelperValue(event.target.value)
        //const newDate = dayjs(referenceValue)
        //const newDate = new Date(referenceValue)
        //newDate.setMonth(newDate.getMonth() + event.target.value)
        const newDate = dayjs(referenceValue).add(event.target.value, 'month')
        console.log("New date: ", newDate)
        inputChange(newDate)
    }

    const dayChange = (event) => {
        setHelperValue(null)
        inputChange(event)
    }

    return (
        <>
        <FormControl>
        <InputLabel id="delaisInputLabel">Délais</InputLabel>
            <Select 
                inputProps={{'aria-label': "selDelay"}}
                labelId="delaisInputLabel"
                id="delaisInput"
                value={helperValue}
                label="Délais"
                onChange={helperChange}
            >
                <MenuItem inputProps={{'aria-label': "1m"}} value={1}>1 mois</MenuItem>
                <MenuItem inputProps={{'aria-label': "3m"}} value={3}>3 mois</MenuItem>
                <MenuItem inputProps={{'aria-label': "6m"}} value={6}>6 mois</MenuItem>
                <MenuItem inputProps={{'aria-label': "12m"}} value={12}>1 ans</MenuItem>
                <MenuItem inputProps={{'aria-label': "36m"}} value={36}>3 ans</MenuItem>
            </Select>
        </FormControl>
        <DayPicker 
            value={value}
            inputChange={dayChange}
            label={label}
            required={required}
            minDate={minDate}
            maxDate={maxDate}
        />
        </>
    )
}


export default DayHelpPicker