import React, { useEffect, useState } from 'react'
import EmptyView from '../view/EmptyView';
import { Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Headers from '../../api/Headers';
import { DisplayTable } from './HeaderTable';
import { useDispatch } from 'react-redux';
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import {CreateButtonSection} from '../view/CreateButtonSection';
import {updateHeader} from './BaseHeader'

export function ViewCompagnieHeader({navigateAction, handleEdit}) {
  const [t, i18n] = useTranslation()
  const [isEditMode, setIsEditMode] = useState(true);
  const dispatch = useDispatch()

  const [curHeader, setCurHeader] = useState(null);

  useEffect(()=>{
    if (!curHeader){
      dispatch(addLoadingCount())
      new Headers()
        .Get("default.json")
        .then(setCurHeader)
        .finally(()=>dispatch(subLoadingCount()))
    }
},[]);


  if (curHeader === undefined || curHeader === null) {
    return (
      <EmptyView title={t('header.noSelection')}>
        
      </EmptyView>
    )
  }


  const handleInputChange = (fieldName, value) => {
    setCurHeader( {...curHeader,
      [fieldName]: value
    })
  };

  const getView =(curHeader, isEditMode) => {
    return (
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
        <div border="1" >
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left" variant='h5'>
            {t('header.description')}
            </Typography>
            <form id="cieForm" onSubmit={(e) => updateHeader(e, curHeader, null, dispatch, t)}>
              <DisplayTable items={curHeader} handleInputChange={handleInputChange} isEditMode={isEditMode} />
              <CreateButtonSection />
            </form>
          </div>
        </Paper>
      )
  }

    return getView(curHeader, isEditMode)
}

