
import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import {CreateButtonSection} from '../view/CreateButtonSection';
import { messageStore } from '../common/InfoMessage';
import EmptyView from '../view/EmptyView';
import {setNextNoHandler } from '../view/TableEditionHandler';
import { emptyClient, GetClientSection } from './BaseClient';
import { ReserveClientNo } from '../../api/Common';
import Clients from '../../api/Clients';
import { useTranslation } from 'react-i18next';
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';

const itemNoFieldName = "clientNo"

export function CreateClient({itemToEdit, saveCallback}) {
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch();
  const isUpdate = (itemToEdit)
  const [client, setClient] = useState(isUpdate ? itemToEdit : emptyClient);
  const [originalClientKey, setOriginalClientKey] = useState(isUpdate ? itemToEdit.key : null);

  React.useEffect(() => {
    handleSetNo();
  }, [client, isUpdate]);

  const handleSetNo = () => {
    if (!isUpdate && (client.no === "" || client.no >= 9999999)) {
      console.log("Need new no")
      setNextNoHandler(itemNoFieldName, client, setClient, dispatch)
    }
  }
  
  // Fonction pour mettre à jour les valeurs des champs
  const handleInputChange = (fieldName, value) => {
    if (client[fieldName] !== value) {
      //console.log("current client : ", client)
      var newClient = {...client,
          [fieldName]: value
      } 
      setClient(newClient)
    }
  };

  const internalHandleSave = (event) => {
    event.preventDefault();
    const api = new Clients()
    dispatch(addLoadingCount())
    const newGeneratedKey = api.generateNewKey(client)

    if (isUpdate && originalClientKey && originalClientKey !== newGeneratedKey) {
      return renameClient(client, originalClientKey, saveCallback, dispatch, t)
        .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
        .then(() => dispatch(subLoadingCount()))
    } else if (isUpdate) {
      return updateClient(client, originalClientKey, saveCallback, dispatch, t)
      .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
      .then(() => dispatch(subLoadingCount()))
    } else {
      return new ReserveClientNo(client.no).then(() => {
        return createClient(client, saveCallback, dispatch, t)
      })
      .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
      .then(() => dispatch(subLoadingCount()))
    }
  }


  const getView =(client) => {
    return (
    <Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }} component={'span'}>

      <form id="clientForm"  onSubmit={internalHandleSave}>
  
        <Typography sx={{ my: 1, mx: 2 }} color="text.secondary" align='center' variant="h5">
        {t('client.mainTitle')}
        </Typography> 
        <GetClientSection clientData={client} isEditMode={true} handleInputChange={handleInputChange}/>
        <br/>
        <CreateButtonSection />
      </form>
    </Paper>)
  }

  if (client === undefined || client === null) {
    return (<EmptyView title={t('client.noSelection')} />)
  }

  return getView(client)
}


const updateClient = (client, originalClientKey, saveCallback, dispatch, t) => {
  console.log("Update client")
  const api = new Clients()
  const newGeneratedKey = api.generateNewKey(client)
  if (originalClientKey && originalClientKey !== newGeneratedKey) {
    console.log("Required client rename")
    return createClient(client, saveCallback, dispatch, t)
    .then(() => api.delete(originalClientKey))
  } else {
    return api.Update(client).then((result) => {
      if (result.status === 200) {
        //new Clients().Get(newClient.key).then(setClient).then(dispatch)
        saveCallback(result.data)
        messageStore.sendMessage(t('client.updateSuccess'))
      } else {
        messageStore.sendMessage(result.statusText, "error", t('saveError'))
      }
    })
  }
}

const renameClient = (client, originalClientKey, saveCallback, dispatch, t) => {
  console.log("Required client rename")
  const api = new Clients()
  return createClient(client, saveCallback, dispatch, t)
  .then(() => api.delete(originalClientKey))
  .then(() => window.location.reload())
}

const createClient = (client, saveCallback, dispatch, t) => {
  console.log("Create client")
  return new Clients().Insert(client).then((result) => {
    if (result.status === 200) {
      saveCallback(result.data)
      //new Clients().Get(newClient.key).then(setClient).then(dispatch).then(() => {saveCallback(newClient)})
      messageStore.sendMessage(t('client.saveSuccess'))
    } else {
      messageStore.sendMessage(result.statusText, "error", t('saveError'))
    }
  })
}

