import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Tooltip } from '@mui/material';

const LB_DATA = "lb"
const KG_DATA = "kg"
const LB_LABEL = "Lb"
const KG_LABEL = "Kg"

const CONVERT_MULTIPLICATOR = 2.205


function WeightInput({isEditMode, weight, weightType, onWeightChange, onWeightTypeChange, defaultWeightType}) {
    const [curWeight, setCurWeight] = React.useState(weight);
    const [curWeightType, setCurWeightType] = React.useState( weightType ? weightType : defaultWeightType );
    const [tooltip, setTooltip] = React.useState(null);

    React.useEffect(() => {
        setupTooltip(curWeight, curWeightType);
      }, [curWeightType, curWeight]);

    const getWeightType = () => {
        return weightType ? weightType : defaultWeightType
    }

    const handleWeightTypeChange = (value) => {
        setCurWeightType(value)
        if (onWeightTypeChange) {
            onWeightTypeChange(value)
        }
    };

    const handleWeightChange = (value) => {
        setCurWeight(value)
        if (onWeightChange) {
            onWeightChange(value)
        }
    };

    const setupTooltip = (weight, weightType) => {
        if (weight && weight !== undefined && weight !== "") {
            const curWeightText = WeightText({weight: weight, weightType: weightType, displayWeightType: weightType})
            const convertWeightText = WeightText({weight: weight, weightType: weightType, displayWeightType: weightType === LB_DATA ? KG_DATA : LB_DATA})
            
            setTooltip(curWeightText + " => " + convertWeightText)
        }
    }

    const getTooltip = () => {
        if (!tooltip || tooltip === undefined || tooltip === "") {
            setupTooltip(weight, weightType)
        }

        return tooltip
    }

      return (
    <>
    
        <FormControl>
            <RadioGroup 
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"    
                onChange={(e) => handleWeightTypeChange(e.target.value)}
                value={getWeightType()}>
                <Tooltip title={getTooltip()}>
                    <TextField label="Poids" sx={{ my: 2, mx: 2 }}
                        variant={isEditMode ? 'outlined' : 'standard'}
                        disabled={!isEditMode} InputLabelProps={{ shrink: true }}
                        inputProps={{ 'aria-label': "txtWeight" }}
                        onChange={(e) => handleWeightChange(e.target.value)}
                        value={weight} type="number"/>
                </Tooltip>
                <FormControlLabel disabled={!isEditMode} value={LB_DATA} control={<Radio inputProps={{ 'aria-label': "optLb" }}/>} label={LB_LABEL} />
                <FormControlLabel disabled={!isEditMode} value={KG_DATA} control={<Radio inputProps={{ 'aria-label': "optKg" }}/>} label={KG_LABEL} />
            </RadioGroup>
        </FormControl>  
    </>)
}

function WeightText({weight, weightType, displayWeightType}) {


    const getLabel = (weightType) => {
        return weightType === LB_DATA ? LB_LABEL : KG_LABEL
    }

    const convertToLb = (weight) => {
        return Number(weight * CONVERT_MULTIPLICATOR).toFixed(1)
    }
    const convertToKg = (weight) => {
        return Number(weight / CONVERT_MULTIPLICATOR).toFixed(1)
    }

    const getWeightDisplay = (weight, weightType, displayWeightType) => {
        const lblDisplay = getLabel(displayWeightType)
        if (weightType === displayWeightType) {
            return weight + " " + lblDisplay
        }
        if (displayWeightType === LB_DATA) {
            return convertToLb(weight) + " " + lblDisplay
        } else {
            return convertToKg(weight) + " " + lblDisplay
        }
    }

      return (getWeightDisplay(weight, weightType, displayWeightType))
}



export default WeightInput
export {WeightText, LB_DATA, KG_DATA, LB_LABEL, KG_LABEL}

