import React  from 'react';
import { Autocomplete, TextField } from "@mui/material"
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const VaccineSelector = ({animal, vaccine, setVaccine, disable}) => {
  const { t, i18n } = useTranslation();
  return (<Autocomplete
    aria-label="lstVaccines"
    disabled={disable}
    disablePortal
    disableClearable
    onChange={(event, newValue) => setVaccine(newValue)}
    sx={{ maxWidth: 500, width: '100%%', my: 5, mx: 2 }}
    value={vaccine === undefined ? null : vaccine}
    options={animal?.vaccines ? animal.vaccines : []}
    getOptionLabel={selectOptionTitre}
    renderInput={(params) => <TextField {...params} label={t('vaccine.selectorTitle')}/>}
  />)
}

const selectOptionTitre = (option) => {
  return "#" + option.no + "  " + option.date
}

VaccineSelector.propTypes = {
  animal: PropTypes.object,
  vaccine: PropTypes.object,
  setVaccine: PropTypes.func.isRequired,
  disable: PropTypes.bool
};
  

export default VaccineSelector