import { React, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DeleteIconContent } from '../common/IconFactory';
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import { useDispatch } from 'react-redux';

export function DeleteItem({itemName, handleDelete}) {
    const dispatch = useDispatch()
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleShowDialog = () => {
        setDialogOpen(true)
    };

    const handleDialogConfirm = () => {
        setDialogOpen(false)
        
        console.log("Confirmation de suppression de ", itemName)
        dispatch(addLoadingCount())
        handleDelete()
          .finally(()=>dispatch(subLoadingCount()))
    };
    
    const handleDialogCancel = () => {
    setDialogOpen(false)
    };


    return (
        <>
        <Button variant="contained" onClick={handleShowDialog} aria-label='btnDelete' ><DeleteIconContent/>Supprimer</Button>
          <Dialog
            open={dialogOpen}
            onClose={handleDialogCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Confirmation de suppression
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              Êtes-vous certain de vouloir supprimer l'élément
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button aria-label='btnCancellDelete' onClick={handleDialogCancel}>Annuller</Button>
              <Button aria-label='btnConfirmDelete' onClick={handleDialogConfirm} autoFocus>
                Supprimer
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
}