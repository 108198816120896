import { HasRole } from "./authenticate"

//Profile
const PROFILE_TEC = "tec-profile"
const PROFILE_VET = "vet-profile"
const PROFILE_READONLY = "read-only-profile"
export const hasProfileTec = () => {
    return HasRole(PROFILE_TEC)
}
export const hasProfileVet = () => {
    return HasRole(PROFILE_VET)
}
export const hasProfileReadOnly = () => {
    return HasRole(PROFILE_READONLY)
}
//Module
const MOD_RX = "module-rx"
const MOD_VACCINE = "module-vaccine"
const MOD_BILLING = "module-billing"
const MOD_CONSULTATION = "module-consultation"
const MOD_EVENT = "module-incomming-event"
const MOD_CONFIRM_EVENT = "module-confirm-event"
export const hasModuleRx = () => {
    return HasRole(MOD_RX)
}
export const hasModuleVaccine = () => {
    return HasRole(MOD_VACCINE)
}
export const hasModuleBilling = () => {
    return HasRole(MOD_BILLING)
}
export const hasModuleConsultation = () => {
    return HasRole(MOD_CONSULTATION)
}
export const hasModuleInccomingEvent = () => {
    return HasRole(MOD_EVENT)
}
export const hasModuleEventConfirm = () => {
    return HasRole(MOD_CONFIRM_EVENT)
}


//Permission
const P_SIGN = "permission-signature"
export const hasPermissionSignature = () => {
    return HasRole(P_SIGN)
}