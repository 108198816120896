import React  from 'react';
import { Autocomplete, TextField } from "@mui/material"
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const RxSelector = ({animal, rx, setRx, disable}) => {
  const { t, i18n } = useTranslation();
  return (<Autocomplete 
    aria-label="lstRxs"
    disabled={disable}
    disablePortal
    disableClearable
    onChange={(event, newValue) => setRx(newValue)}
    sx={{ maxWidth: 500, width: '100%%', my: 5, mx: 2 }}
    value={rx === undefined ? null : rx}
    options={animal?.rxs ? animal.rxs : []}
    getOptionLabel={selectOptionTitre}
    renderInput={(params) => <TextField {...params} label={t('rx.selectorTitle')}/>}
  />)
}

const selectOptionTitre = (option) => {
  return "#" + option.no + "  " + option.date
}


RxSelector.propTypes = {
  animal: PropTypes.object.isRequired,
  rx: PropTypes.object.isRequired,
  setRx: PropTypes.func.isRequired,
  disable: PropTypes.bool
};


export default RxSelector